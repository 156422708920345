import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage as FormikErrorMessage } from 'formik';
import style from './ErrorMessage.module.scss';
import classNames from 'classnames';

const ErrorMessage = ({ name, helperProps }) => {
  return (
    <FormikErrorMessage
      name={name}
      render={(message) => (
        <p
          className={classNames(
            style['dont-break-out'],
            helperProps.className,
          )}
          style={{
            color: 'red',
            ...helperProps.style,
          }}
        >
          {message}
        </p>
      )}
    />
  );
};

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
  helperProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.object,
  }),
};

ErrorMessage.defaultProps = {
  helperProps: {},
};

export default ErrorMessage;
