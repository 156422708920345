import { PhoneNumberField } from 'components/formFields';
import validator from 'lib/validator';
import { postSetUserPhoneNumber, useUser } from 'lib/account';
import { useAuth } from 'lib/auth';
import { Form, FormManager } from 'lib/form';
import { isEmpty } from 'lib/javascript';
import notification from 'lib/notification';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from 'components/common/Button';
import Modal from 'components/common/Modal';

const validationSchema = validator.object({
  phone_number: validator
    .string()
    .phone()
    .nullable()
    .required('Required'),
});

const InputPhoneNumberModal = () => {
  const { session } = useAuth();
  const { user, mutate } = useUser(session?.user?.userId);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (session && user && isEmpty(user.phone_number)) {
      setOpen(true);
    } else {
      if (isOpen) {
        setOpen(false);
      }
    }
  }, [user, session, isOpen, setOpen]);

  const handleOnSubmit = async ({ phone_number }, actions) => {
    try {
      await postSetUserPhoneNumber(user.user_id, { phone_number });
      notification.success('Yay! Update Phone Number successfully.');
      setOpen(false);
    } catch (err) {
      actions.setErrors(err.errors);
    } finally {
      mutate();
      actions.setSubmitting(false);
    }
  };

  if (!session) {
    return null;
  }

  return (
    <React.Fragment>
      <Modal
        open={isOpen}
        onHide={() => {}}
        isShowCloseButton={false}
      >
        <div style={{ padding: 36 }}>
          <FormManager
            initialValues={{
              ...user,
            }}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
          >
            {(props) => {
              const { isSubmitting } = props;

              return (
                <Form>
                  <p>
                    To ensure deliveries of your meals go smoothly,
                    we're gonna need your contact number!
                  </p>
                  <p>
                    PS: We don't do any form of SMS marketing so you
                    can relax. We won't spam message you! ;)
                  </p>
                  <Row>
                    <Col>
                      <PhoneNumberField name="phone_number" />
                    </Col>
                  </Row>
                  <Row>
                    <Button
                      type="submit"
                      variant="outlined"
                      loading={isSubmitting}
                    >
                      <span>UPDATE</span>
                      <i className="icon-long-arrow-right"></i>
                    </Button>
                  </Row>
                </Form>
              );
            }}
          </FormManager>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default InputPhoneNumberModal;
