import * as yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

const addMethods = () => {
  const PHONE = 'phone';

  yup.addMethod(
    yup.string,
    PHONE,
    function testPhone(errorMessage) {
      const errMsg =
        typeof errorMessage === 'string' && errorMessage
          ? errorMessage
          : // eslint-disable-next-line no-template-curly-in-string
            'Must be a valid phone number.';

      return this.test(PHONE, errMsg, (value) => {
        try {
          return isValidPhoneNumber('+' + value);
        } catch (err) {
          return false;
        }
      });
    },
  );
};

export default addMethods;
