import React from 'react';
import { Formik, yupToFormErrors } from 'formik';
import { mapValues, isPlainObject } from 'lib/javascript';

const setAllFieldsTouched = (fieldValues) =>
  mapValues(fieldValues, (value) =>
    isPlainObject(value)
      ? setAllFieldsTouched(value) // recursive to set nested object fields
      : true,
  );

const FormManager = ({
  validateOnMount,
  initialValues,
  initialTouched,
  initialErrors,
  validationSchema,
  ...props
}) => {
  const getInitialErrors = () => {
    if (initialErrors) return initialErrors;

    if (!validateOnMount) return;

    // workaround for validateOnMount=true,
    // currently there's a bug where the initial errors resurfaces after submitting
    // track this issue: https://github.com/formium/formik/issues/3305
    try {
      validationSchema.validateSync(initialValues, {
        abortEarly: false,
      });
    } catch (err) {
      return yupToFormErrors(err);
    }
  };

  const getInitialTouched = () => {
    if (initialTouched) return initialTouched;

    // workaround for validateOnMount=true (see comment above for more details)
    // touch all errored fields, errors are only showed when a field is touched
    if (validateOnMount)
      return setAllFieldsTouched(getInitialErrors());

    return {};
  };

  return (
    <Formik
      {...props}
      initialValues={initialValues}
      initialErrors={getInitialErrors()}
      initialTouched={getInitialTouched()}
      validationSchema={validationSchema}
    />
  );
};

export default FormManager;
