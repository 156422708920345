/* eslint-disable no-template-curly-in-string */
import { setLocale as yupSetLocale } from 'yup';

const setLocale = () => {
  yupSetLocale({
    mixed: {
      required: 'This field is required',
    },
    array: {
      min: 'This field must have at least ${min} items',
      max: 'This field must have less than or equal to ${max} items',
    },
    string: {
      email: 'Please enter a valid email.',
    },
  });
};

export default setLocale;
