import React from 'react';
import { useFormikContext } from 'formik';

const Form = (props) => {
  const formik = useFormikContext();

  return (
    <form
      onReset={formik.handleReset}
      onSubmit={
        formik.isSubmitting
          ? (ev) => ev.preventDefault()
          : formik.handleSubmit
      }
      {...props}
    />
  );
};

export default Form;
