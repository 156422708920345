import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { getLabel } from '../utils';
import styles from './PhoneNumberInput.module.scss';
import cn from 'classnames';

const DEFAULT_COUNTRY = 'sg';

const PhoneNumberInput = ({
  name,
  placeholder,
  required,
  error,
  label,
  helperText,
  value,
  onChange,
  onBlur,
  disabled,
  disableDropdown,
  disableCountryCode,
  countryCodeEditable,
  country,
  onlyCountries,
  formGroupProps,
  helperProps,
}) => {
  return (
    <div
      className={cn('form-group', formGroupProps?.className)}
      style={formGroupProps?.style}
    >
      {label && (
        <label htmlFor={name} style={error ? { color: 'red' } : {}}>
          {getLabel(label, required)}
        </label>
      )}
      <PhoneInput
        country={country || onlyCountries?.[0]}
        onlyCountries={onlyCountries}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        disableDropdown={disableDropdown}
        disableCountryCode={disableCountryCode}
        countryCodeEditable={countryCodeEditable}
        inputProps={{ name, required }}
        inputClass={styles['form-control']}
      />

      {helperText && (
        <p
          style={{
            ...(helperProps?.style || {}),
            ...(error ? { color: 'red' } : {}),
          }}
          className={cn(helperProps?.className)}
        >
          {helperText}
        </p>
      )}
    </div>
  );
};

PhoneNumberInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  disableDropdown: PropTypes.bool,
  disableCountryCode: PropTypes.bool,
  countryCodeEditable: PropTypes.bool,
  country: PropTypes.string,
  onlyCountries: PropTypes.arrayOf(PropTypes.string),
  formGroupProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.object,
  }),
};

PhoneNumberInput.defaultProps = {
  onBlur: () => {},
  error: false,
  disabled: false,
  // disableDropdown: COUNTRIES.length === 1,
  countryCodeEditable: true,
  disableCountryCode: false,
  country: DEFAULT_COUNTRY,
  // onlyCountries: COUNTRIES,
};

export default PhoneNumberInput;
