import setLocale from './setLocale';
import mergeSchemas from './mergeSchemas';
import addMethods from './addMethods';
import * as yup from 'yup';

// must setLocale before yup is imported for validation, otherwise the custom error message won't be applied
// reference: https://github.com/jquense/yup/issues/293
setLocale();

addMethods(); // load additional custom methods

export { mergeSchemas };
export default yup;
